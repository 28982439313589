.home-tabs {

    .nav-tab {

        padding: 10px 15px 10px 10px;

        .tab-title {
            font-size: 18px;
            margin: 0;
        }
    } 

    .nav-tab.active {
        font-family: Roboto-Medium, "Roboto Medium", Roboto, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: rgb(52, 127, 187);
        border: none;
        border-bottom: 3px solid #347fbb;
        text-decoration-line: none;
    }

    .nav-tab:hover {
        text-decoration-line: none;
    }
}