.simple-tooltip {
    display: inline;
    position: relative;

    .body {
      &:hover {
        & + .tooltip {
          display: block;
        }
      }
    }

    .tooltip {
      text-align: left;
      width: 120px;
      height: 35px;
      position: absolute;
      padding: 6px 8px;
      left: 30px;
      bottom: -30px;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
      box-sizing: border-box;
      display: none;
      transition: all .1s ease-in;
      white-space: normal;
      list-style-type: none;
      z-index: 1;

      .title {
        font-size: 14px;
        margin: 3px 0 4px 0;
      }
      .subtitle {
        font-size: 13px;
        margin: 0;
      }
    }
  }
