.fileSyncPage {
    .import-disclaimer {
        background-color: #F6EDCE;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 12px;
        word-break: break-word;
        white-space: normal;
        color: rgba(0, 0, 0, 0.8);
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}