.table-container {
  .table-header-label {
    font-size: 14px;
    color: black;
    text-align: right;
  }

  .sync-table-container {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.28);

    .sync-table {
      color: rgba(47, 47, 47);
      font-family:'Roboto-Medium', 'Roboto Medium', 'Roboto', sans-serif;
      font-size: 14px;
      word-wrap: break-word;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      table-layout:fixed;

      thead {
        background-color: rgba(229, 229, 229, 1);
        text-align: left;

        th {
          background-color: rgba(229, 229, 229, 1);
          font-weight: 600;
          padding: 8px;
        }
      }

      tbody {
        font-weight: 400;
      }

      tr td {
        padding: 8px;
      }

      tr:nth-child(even) {
        background-color: rgba(246, 246, 246, 1);
      }

      tr:nth-child(odd) {
        background-color: rgba(255, 255, 255, 1);
      }

      td {
        .truncate {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }
      }

    }

    .table-footer {
      padding: 0 5px 5px;
      font-size: 14px;
      color: rgba(47, 47, 47);

      div, button {
        display: inline-block;
      }

      button {
        color: #0089d6;
        border-radius: 4px;
        border: 1px solid #208dca;
        padding: 5px 10px;
        height: 30px;
      }

      button[disabled] {
        border: 1px solid darkgrey;
        color: darkgrey;
      }

      select {
        margin: 5px;
      }
    }
  }

  .empty-table-message{
    font-size: 28px;
    margin-top: 2em;
    color: #000;
  }
}
