.auditTrail-wrapper {
  width: 100%;
  min-width: 700px;
  max-width: 1200px;

    .auditTrail {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      min-width: 700px;
      max-width: 1200px;
      padding-bottom: 5px;

      .event-details {
        margin-bottom: 5px;

        .event-details-row-key {
          min-width: 100px;
        }
      }

      .actions-column {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }

      [role='cell'] {
        vertical-align: top;
      }

      .icon {
        margin-right: 0;
      }
      .infoTooltip .icon {
        margin: 0;
      }
    }

    .auditTrail-note {
      font-size: 16px;
      background-color: #F6EDCE;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      margin: 20px 0px;
      padding: 12px;
      word-break: break-word;
      white-space: normal;
      color: rgba(0, 0, 0, 0.8);
      align-items: center;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      align-items: flex-start;
      justify-content: space-around;
      p {
        margin: 0;
      }
      .linkButton {
        margin-left: 5px;
      }
    }
}