.token-form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
    min-width: 700px;
    max-width: 1200px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;

    .hidden {
        display: none !important;
    }

    .token-page-header {
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        color: #000000;
        text-align: left;
        font-kerning: normal;
        text-rendering: optimizeLegibility;
    }

    .errors {
        border: 1px solid #D13C3C;
        background: rgba(255, 255, 255, 0.97);
        color: #D13C3C;
        padding: 10px;
        margin-bottom: 15px;
        display: block;
        width: 100%;
    }

    .token-row {
        font-size: 16px;
        display: flex;
        grid-template-columns: 210px auto;
        grid-column-gap: 16px;
        column-gap: 16px;
        margin-bottom: 24px;

        input {
            width: 60%;
            max-width: 200px;
            border-radius: 4px;
            border: 1px solid #cacaca;
            height: 32px;
            outline: none;

        }

        input:focus {
            border: 1px solid #0089d6;
        }

        input.long {
          width: 80%;
          min-width: 30%;
        }

        label {
            padding-top: 8px;
            text-align: right;
            color: #333;
            box-sizing: border-box;
            width: 90px;
        }
        button {
            width: max-content;
            padding: 4px;
        }
    }

    .buttonPrimary {
        margin-right: 16px;
        background-color: #196fb3;
        color: #fff;
        transition: all .1s ease-in;

        &:hover {
          background-color: darken(#196fb3, 5%);
        }
        &[disabled] {
          background-color: #e5e5e5;
        }
      }

    .link-button {
        background-color: transparent;
        font-size: 10px;
        border: none;
        cursor: pointer;

        &.copy {
            padding: 0;
        }

        &:disabled {
            background-color: transparent;
            box-shadow: none;
            cursor: not-allowed;

            .tooltip {
                color: #5D5D5D;
            }
        }

        &:focus {
            box-shadow: none;
        }
    }

    .simple-tooltip {
        .tooltip {
            width: 90px;
            color: #196fb3;
            left: 10px;
            font-weight: 400;

            .title {
                text-align: center;
            }
        }

        .copy-icon:hover {
            background-color: transparent;
            color: #196fb3;
            text-decoration-line: underline;
            box-shadow: none;
        }
    }
}

