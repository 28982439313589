.infoTooltip {
  display: inline;
  position: relative;

  .icon {
    margin-left: 4px;
    color: #208dca;
    cursor: pointer;

    &:hover {
      & + .tooltip {
        display: block;
      }
    }
  }

  .tooltip {
    text-align: left;
    width: 250px;
    position: absolute;
    padding: 12px 16px;
    left: 24px;
    bottom: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
    box-sizing: border-box;
    display: none;
    transition: all .1s ease-in;
    white-space: normal;
    list-style-type: none;
    z-index: 1;
    
    .title {
      font-size: 14px;
      margin: 0 0 12px;
    }
    .subtitle {
      font-size: 13px;
      margin: 0;
    }
  }
}
  