.sync-modal--buttons {
    .link-button {
      background-color: transparent;
      color:rgb(25, 111, 179);
      padding: 0;
      box-shadow: none;
      height: auto;
      cursor: pointer;
  
      font-weight: 700;
      margin-left: 5px;
    }
  
    .link-button:hover {
        background-color: transparent;
        color:rgb(25, 111, 179);
        text-decoration-line: underline;
        box-shadow: none;
      }
    
    .link-button:disabled {
        background-color: transparent;
        color: grey;
        box-shadow: none;
        cursor:not-allowed;
    }

    button {
      border: none;
      height: 40px;
      font-weight: 700;
      border-radius: 4px;
      line-height: 16px;
      padding: 8px 16px;
      display: inline-block;
      align-items: center;
      cursor: pointer;
    
      &[disabled] {
        color: #999;
        cursor: not-allowed;
      }
    }

    button.delete-button {
        border: 1px solid #D13C3C;
    }
    
    .buttonPrimary {
      margin-right: 16px;
      background-color: #196fb3;
      color: #fff;
      transition: all .1s ease-in;
    
      &:hover {
        background-color: darken(#196fb3, 5%);
      }
      &[disabled] {
        background-color: #e5e5e5;
      }
    }
    
    .buttonSecondary {
      background-color: #fff;
      color: #196fb3;
      transition: all .1s ease-in;
    
      &:not([disabled]):hover {
        text-decoration: underline;
      }
      &[disabled] {
        background-color: #fff;
      }
    }
  }

.sync-modal--title, .sync-modal--text {
  color:black;
}

.sync-modal--errors {
  color: #B21717;
  border: 1px solid #B21717;
  padding-left: 5px;
  margin:10px;
}