.back-to-link {
  width: 150px;
  font-size: 15px;
  color: #196fb3;
  text-align: left;
  margin: 5px 0;

  a {
    text-decoration: none;
    color: #196fb3;
  }

  a:hover {
    border-bottom: 1px solid #196fb3;
    color: rgb(25, 111, 179, 0.8);
  }

  .icon-back {
    margin: 0 5px 0 0;
    color: #196fb3;
  }
}
