.scim-logs-page {
  width: 100%;
  font-size: 16px;
  color: black;

  .header {
    font-weight: 400;
  }

  .scim-logs-container {
    margin: 0 auto;
    width: 100%;
    min-width: 700px;
    max-width: 1200px;
    padding: 15px;
  }
}
