@import url('https://fonts.googleapis.com/css?family=Roboto');
.nav-tab {
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    display: inline-block;
    padding: 10px 25px;
    color: black;
    border-bottom: 1px solid #ddd;
    text-decoration: none;
    &:hover {
        background-color: oldlace;
        text-decoration: none;
    }
    &.active {
        background-color: #fff;
        color: #555;
        border: 1px solid #ddd;
        border-top: 2px solid orange;
        border-bottom: 1px solid #fff;
        cursor: default;
        text-decoration: none;
    }
}