.permanent-token-page {
    .token-page-header {
        font-weight: 400;
        font-size: 28px;
        color: #000;
        text-align: left;
        font-kerning: normal;
        text-rendering: optimizeLegibility;
    }

    .page-instructions {
        font-size: 16px;
        color: #000;

        .instructions-item {
            display: flex;
            flex-wrap: wrap;
        }

        .step {
            display: flex;
            margin-bottom: 20px;

            .step-order {
                min-width: 54px;
                padding-left: 2px;
                word-break: keep-all;
                margin-right: 5px;
                font-weight: bold;
            }

            .step-text {
                .arrow {
                    margin: 0 5px;
                }

                &.link {
                    a {
                        color: #196fb3;
                        margin-left: 5px;
                        font-weight: 700;
                        text-decoration: none;
                        border-bottom: 1px solid #196fb3;
                    }

                    a:hover {
                        border-bottom: 2px solid rgb(25, 111, 179, 0.8);
                        color: rgb(25, 111, 179, 0.8);
                    }
                }
            }
        }


        .warning-text {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            font-weight: bold;

            .step-text {
                margin-left: 7px;
            }
        }

        p {
            margin: 0 0;
        }
    }

    .note {
        margin-bottom: 20px;
        padding: 12px;
        background-color: #F6EDCE;
        font-size: 15px;
        border-radius: 4px;
        color: #000;

        .note-text {
            line-height: 22px;
        }
    }

    .tenant-row {
        .tenant-url {
            width: 80%;
            min-width: 30%;
            max-width: 200px;
            border-radius: 4px;
            border: 1px solid #cacaca;
            height: 32px;
            outline: none;
        }
    }
}
