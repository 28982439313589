.syncSessionsHistory {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 700px;
    max-width: 1200px;
    padding-bottom: 5px;

    .actions-column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        div {
            min-width: 20%;
        }

        .link-button {
            cursor: pointer;
            background-color: transparent;
            color: rgb(25, 111, 179);
            font-weight: 400;
            text-decoration-line: none;
            font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
            font-size: 14px;
            box-shadow:none;
        }

        .link-button.red {
            color: #B21717;
            padding: 0;
        }

        .link-button.default {
            padding: 0;
        }

        .link-button:hover {
            text-decoration: underline;
            background-color: transparent;
        }
    }
}
