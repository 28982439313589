.recurrent-sync-component {
    .instructions {
        label {
            display: block;
            margin-top: 5px;
        }
        .step-label {
            font-size: 18px;
        }
        ul {
            list-style: circle;
            padding-left: 20px;

            li {
                margin-bottom: 8px;
            }
        }
        
    }
    
    .token-form-section {
        padding: 8px 8px 0 0;
        margin: 5px 5px 0 0;
        display: inline-block;
    }

    .bold {
        font-weight: bold;
        display: inline;
    }

    .code-block {
        background-color:#012456;
        padding: 8px;
        border-left: 4px solid khaki;
        margin: 8px 0 8px 0;

        label {
            display: inline-block;
            margin: 0;
        }

        .yellow {
            color:#FFFF00
        }

        .white {
            color: #EEEDF0;
        }

        .grey {
            color: lightgrey;
        }
    }

    .collapsible {
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        border-bottom: 1px solid #eaecf0;
        background-color: transparent;
    }
      
    .collapsible-content {
        padding: 10px 18px;
        display: none;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }

    .collapsible-content.active {
        display: block;
    }
    
    .collapsible-content:disabled {
        color:grey;
    }
    .row {
        label, input {
            display: inline-block;
        }
        input {
            margin-right: 5px;
        }
    }
    .sync-variables {
        padding: 5px;
        margin: 5px;
        tr td:first-child {
            text-align: right;
        }
        tr td {
            padding-left: 5px;
        }
    }

    .variable-row {
        font-size: 16px;
        display: flex;
        grid-template-columns: 210px auto;
        grid-column-gap: 16px;
        column-gap: 16px;
        margin-bottom: 24px;

        input {
            width: 60%;
            max-width: 200px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border: 1px solid #cacaca;
            height: 32px;
            outline: none;

        }

        input.long {
          width: 80%;
          min-width: 30%;      
        }

        label {
            padding-top: 8px;
            text-align: right;
            color: #333;
            box-sizing: border-box;
            width: 90px;
        }
        button {
            width: max-content;
        }
    }
}