.sync-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #0089d6;

  width: 80%;
  min-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1100px) {
    min-width: 720px;
  }

  #app-header {
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    color: #000000;
    text-align: left;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
  }

  .center {
    text-align: center;
  }

  .top-panel {
    margin-bottom: 30px;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .sync-app-main {
    width: 100%;
    min-height: calc(95vh - 55px);
  }

  .App-link {
    color: #61dafb;
  }

  .Main-link a {
    text-decoration: none;
    margin-right: 20px;
  }

  .Main-link a:hover {
    opacity: 0.7;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .logs-table {
    margin-top: 10px;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    max-width: 80%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .logs-table th,
  .logs-table td {
    border: 1px solid black;
    padding: 4px;
  }
}

.navigation-items {
  a {
    font-size: 15px;
    text-decoration: none;
    color: #196fb3;

    &:hover {
      border-bottom: 1px solid #196fb3;
      color: rgb(25, 111, 179, 0.8);
    }
  }
}

.flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.mt-10 {
  margin-top: 10px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.gap-x-20 {
  column-gap: 20px;
}