.modal--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal--closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  
  .sync-modal--title {
    margin: 0 0 16px;
    line-height: 24px;
    font-size: 20px;
    font-weight: normal;
  }
  
  .sync-modal--text {
    font-size: 14px;
    line-height: 24px;
  }
  
  .sync-modal--buttons {
    display: flex;
    margin-top: 24px;
  }
  