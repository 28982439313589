.sync-session-form-page {
    font-size: 16px;
    padding: 40px 40px 40px 10px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.18);

    .fileSyncForm {
        &--row {
          display: grid;
          grid-template-columns: 210px auto;
          column-gap: 16px;
          margin-bottom: 24px;
        }
        &--label {
          padding-top: 8px;
          text-align: right;
          color: #333;
        }
        &--fileName {
          color: #797979;
          display: block;
        }
        &--buttons {
          display: flex;
          margin-top: 16px;
        }
    
        input,
        select {
          outline: none;
        }
    
        input[type='text'] {
          max-width: 300px;
        }
        input[type='select'], select {
          color: black;
        }
        input[type='text'],
        select {
          border: 1px solid #cacaca;
          border-radius: 4px;
          height: 32px;
          width: 100%;
          transition: all .1s ease-in;
          color:black;
    
          &:focus {
            border: 1px solid darken(#cacaca, 30%);
          }
        }
    
        input[type='file'] {
          display: none;
        }
    
        .fieldsGrid {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          max-height: 300px;
          
          @media screen and (max-width: 1100px) {
            max-height: unset;
          }
    
          li {
            display: flex;
            margin-bottom: 4px;
            margin-right: 30px;
            max-width: 50%;
    
            p:not(.title, .subtitle) {
              float: left;
              align-items: center;
              padding-left: 12px;
              padding-top: 5px;
              width: 40%;
              min-width: 165px;
              height: 32px;
              margin: 0;
              white-space: nowrap;
              color: #333;
              background-color: #f1f1f1;
              border: 1px solid #cacaca;
              border-right: 0;
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
  
              .infoTooltip{
                position: absolute;
              }
            }
            
            select {
              width: 60%;
              min-width: 170px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              text-overflow: ellipsis;
  
              option {
                text-overflow: ellipsis;
              }
            }
          }
        }
        .uploadButton {
          width: 111px;
          height: 40px;
          background-color: #fff;
          border: 1px solid #208dca;
          border-radius: 4px;
          padding: 8px 16px;
          outline: none;
          white-space: nowrap;
          -webkit-user-select: none;
          cursor: pointer;
          font-weight: 700;
          font-size: 14px;
          color: #208dca;
          transition: all .1s ease-in;
    
          &:hover {
            color: #fff;
            background-color: #196fb3;
          }
        }
  
        .uploadButton:disabled, .uploadButton[disabled] {
          background-color: #e5e5e5;
          border: 1px solid #e5e5e5;;
          color: #999;
        }
    
        .required {
          color: #d13c3c;
          margin-left: 0;
        }
      }
  
      .errorsTable {
        border: 1px solid #D13C3C;
        background: rgba(255, 255, 255, 0.97);
        color: #D13C3C;
        padding: 5px;
      }

    button {
        border: none;
        height: 40px;
        font-weight: 700;
        border-radius: 4px;
        line-height: 16px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
      
        &[disabled] {
          color: #999;
          cursor: not-allowed;
        }
      }
      
      .buttonPrimary {
        margin-right: 16px;
        background-color: #196fb3;
        color: #fff;
        transition: all .1s ease-in;
      
        &:hover {
          background-color: darken(#196fb3, 5%);
        }
        &[disabled] {
          background-color: #e5e5e5;
        }
      }
      
      .buttonSecondary {
        background-color: #fff;
        color: #196fb3;
        transition: all .1s ease-in;
      
        &:not([disabled]):hover {
          text-decoration: underline;
        }
        &[disabled] {
          background-color: #fff;
        }
      }
    
      .link-button {
        background-color: transparent;
        padding: 0;
      }
    
      .link-button:disabled {
        color: grey;
      }
    
      html {
        box-sizing: border-box;
        font-family: 'Roboto-Regular', 'Roboto', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      body {
        margin: 0;
        padding: 0;
        color: #333;
      }
      #output {
        height: 100vh;
      }
      
      *, :after, :before {
        box-sizing: border-box;
      }
      
      .container {
        display: flex;
        height: 100%;
      }
      
      .sidebarWrapper {
        background-color: #476990;
        width: 260px;
        padding: 24px 0;
        box-sizing: border-box;
      }
      
      .sidebarTitle {
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        padding-left: 20px;
      }
      .sidebarLinks {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      .sidebarLink {
        color: #fff;
        text-decoration: none;
        padding: 10px 0 10px 35px;
        display: block;
        font-weight: 500;
        transition: all .1s ease-out;
      
        &:hover,
        &:focus {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      
      .contentWrapper {
        width: calc(100% - 260px);
      }
      
      .headline {
        font-size: 20px;
        line-height: 24px;
        padding: 16px 24px;
        margin-bottom: 24px;
        background-color: #fff;
        border-bottom: 1px solid #e9e9e9;
      }
      
      .sectionOuter {
        padding: 0 20px;
      }
      
      .pageSectionWrapper {
        padding: 40px 40px 40px 10px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      }

    .header {
        font-size: 28px;
        line-height: 40px;
        font-weight: normal;
        color:black;
        text-align: left;
    }

    .import-tabs {
        font-size: 16px;
    
        .nav-tab.active {
            font-family: Roboto-Medium, "Roboto Medium", Roboto, sans-serif;
            font-weight: 500;
            font-style: normal;
            color: rgb(52, 127, 187);
            border: none;
            border-bottom: 3px solid #347fbb;
            text-decoration-line: none;
        }
        .nav-tab:hover {
            text-decoration-line: none;
        }
    }
    
    .link-button {
        background-color: transparent;
        color:rgb(25, 111, 179);
        padding: 0;
        font-weight: normal;
        box-shadow: none;
        height: auto;
        cursor: pointer;
    }
    
    .link-button:hover {
        background-color: transparent;
        color:rgb(25, 111, 179);
        text-decoration-line: underline;
        box-shadow: none;
    }
    
    .link-button:disabled {
        background-color: transparent;
        color: grey;
        box-shadow: none;
        cursor:not-allowed;
    }

    .break-line {
        overflow-wrap: anywhere;
    }
}