.entity-retries {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 700px;
    max-width: 1200px;
    padding-bottom: 5px;

    [role="cell"]{
        vertical-align: top;
    }

    .buttonPrimary {
      width: 80px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #208dca;
      border-radius: 4px;
      padding: 4px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      color: #208dca;
      transition: all .1s ease-in;

      &:hover {
        color: #fff;
        background-color: #196fb3;
      }
    }

    .fix-entity-button {
      display: block;
      margin: 10px 0 10px auto;
    }
}

.find-entity-modal--title, .find-entity-modal--text {
  color:black;
  margin: 5px 0;
}

.find-entity-modal--errors {
  color: #B21717;
  border: 1px solid #B21717;
  padding-left: 5px;
  margin:10px;
}

.find-entity-modal--buttons {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    height: 40px;
    font-weight: 700;
    border-radius: 4px;
    line-height: 16px;
    padding: 8px 16px;
    display: inline-block;
    align-items: center;
    cursor: pointer;
  
    &[disabled] {
      color: #999;
      cursor: not-allowed;
    }
  }

  button.delete-button {
      border: 1px solid #D13C3C;
  }
  
  .buttonPrimary {
    margin-right: 16px;
    background-color: #196fb3;
    color: #fff;
    transition: all .1s ease-in;
  
    &:hover {
      background-color: darken(#196fb3, 5%);
    }
    &[disabled] {
      background-color: #e5e5e5;
    }
  }
  
  .buttonSecondary {
    background-color: #fff;
    color: #196fb3;
    transition: all .1s ease-in;
  
    &:not([disabled]):hover {
      text-decoration: underline;
    }
    &[disabled] {
      background-color: #fff;
    }
  }
}

#find-entity-name,
#find-entity-type {
  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 32px;
  transition: all .1s ease-in;
  color:black;

  &:focus {
    border: 1px solid darken(#cacaca, 30%);
  }
}