.import-navigation-panel {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1803921568627451);
    background-color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    color: black;
    padding: 8px;

    p {
        margin-left: 10px;
        text-align: left;
    }
    
    .import-tabs-panel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 10px;

        .action-button {
            border: 1px solid #0089d6;
            margin-right: 20px;
            margin-top: 10px;
            cursor: pointer;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            height: 40px;

            a {
                text-decoration-line: none;
                font-weight: 700;
                display: flex;
                align-items: center;
            }
          
            .button-icon {
              width: 40%;
              height: 80%;
              margin-right: 5px;
            }
          
            label {
              width: 100%;
              cursor: pointer;
              font-size: 14px;
              color:#208DCA;
            }
            
            svg {
                height: 35px;
                width: 35px;
            }

            p {
                color:#208DCA;
            }
        }
    }
    .action-button:hover {
        label, p {
            color: white;
        }
    }

    .action-button:disabled, 
    .action-button:disabled label,
    .action-button:disabled svg  {
        color:gray;
        background-color: darkgray;
        border-color: gray;
    }

    .import-subtabs-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        justify-content: flex-start;

        .sub-tab:first-child {
            margin-left: 3%;
        }

        .sub-tab {
            display: flex;
            align-content: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 5%;
            margin-bottom: 20px;
            margin-top: 20px;
            width: 430px;
            height: 140px;
            color: #5D5D5D;
            border: 1px solid #0089d6;
            background-color: white;
            text-decoration-line: none;

            .logo {
                font-size: 36px;
                line-height: 26px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }

            .header {
                font-size: 24px;
                line-height: 40px;
                font-weight: 700;
                text-align: center;
            }

            p {
                margin-top: 4px;
                margin-bottom: 0;
                font-weight: 400;
            }
            label {
                font-size: 14px;
            }
        }
        .sub-tab:hover {
            margin-right: calc(5% - 4px);
            border: 3px solid #0089d6;
            box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.18);
        }
    }
}