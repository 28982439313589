.syncSessionPage {
    margin: 10px;
    font-size: 16px;
    color: black;
    
    .nav-tab.active {
        font-family: Roboto-Medium, "Roboto Medium", Roboto, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: rgb(52, 127, 187);
        border: none;
        border-bottom: 3px solid #347fbb;
        text-decoration-line: none;
    }
    
    .nav-tab:hover {
        text-decoration-line: none;
    }

    .header {
        font-size: 28px;
        line-height: 40px;
        font-weight: normal;
        color:black;
        text-align: left;
    }

    .break-line {
        overflow-wrap: anywhere;
    }

    .error-message{
        font-size: 28px;
        font-weight: normal;
    }
}