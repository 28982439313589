.file-link {
    cursor: pointer;
    background-color: transparent;
    color: rgb(25, 111, 179);
    font-weight: 700;
    text-decoration-line: none;
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
    font-size: 14px;
    border: none;
}

.file-link:hover {
    text-decoration: underline;
    background-color: transparent;
}